import React from 'react'
import styled from "styled-components"
import { Button, TextField } from '@material-ui/core';
import Cookies from 'universal-cookie';
import moment from 'moment'
import validator from 'validator'


const emailAddress = "lhbuildingandmaintenance@gmail.com"

const Form = styled.div`
  background-color: #e6e8f0;
  padding: 30px;
  border-top: 1px solid #4c5e6f;

  button {
    padding: 10px;
    width: 300px;
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    padding: 30px 50px;

    button {
      width: 100%;
      padding: 10px 0;
      margin: 10px 0;
    }
  }
`

const FormWrapper = styled.div`
  max-width: 960px;
  margin 0 auto;
`

const FormInput = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`

const FormInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const FullFormInput = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`

const initialFormData = Object.freeze({
  name: "",
  nameError: false,
  phone: "",
  phoneError: false,
  email: "",
  emailError: false,
  emailInvalid: false,
  message: "",
  messageError: false,
  sent: false,
  disabled: false
});

const cookies = new Cookies();

const Contact = ({ img, title }) => {

  const recentlySubmitted = cookies.get('contact-submitted');

  const postData = (name, email, message, phone) => {
    var emailPayload = {
      personalizations: [
        {
          to: [
            {
              email: emailAddress
            }
          ],
          subject: `Contact from ${name}`
        }
      ],
      from: {
        name: name,
        email: email
      },
      content: [
        {
          type: "text/plain",
          value: `Message: ${message}\r\nContact Number: ${phone}`
        }
      ]
    };

    fetch('https://contacter.azurewebsites.net/send', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(emailPayload) // body data type must match "Content-Type" header
    }).then(result => {
      cookies.set('contact-submitted', true, {
        expires: moment(new Date()).add(1, 'm').toDate()
      });
      updateFormData({
        ...formData,
        sent: true
      });
    })
  }

  const handleChange = (e) => {
    const errorName = `${e.target.name}Error`;
    let payload = {
      [e.target.name]: e.target.value.trim(),
      [errorName]: false
    };

    if (e.target.name === "email") {
      payload["emailInvalid"] = false
    }
    updateFormData({
      ...formData,
      ...payload
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateFormData({ ...formData, disabled: true });

    let state = { ...formData };
    if (state.name === "") {
      state = {
        ...state,
        nameError: true
      };
    }

    if (state.phone === "") {
      state = {
        ...state,
        phoneError: true
      };
    }
    if (state.email === "") {
      state = {
        ...state,
        emailError: true
      };
    } else if (!validator.isEmail(state.email)) {
      state = {
        ...state,
        emailInvalid: true
      };
    }

    if (state.message === "") {
      state = {
        ...state,
        messageError: true
      };
    }

    if (state.nameError || state.messageError || state.emailError || state.phoneError) {
      updateFormData({
        ...state,
        disabled: false
      });
    } else {
      postData(formData.name, formData.email, formData.message, formData.phone);
    }
  };

  const [formData, updateFormData] = React.useState(initialFormData);

  return (
    <Form>
      <FormWrapper>
        {
          (formData.sent || recentlySubmitted) ?
            <h1>Thanks for getting in touch, we will get back to you shortly.</h1> :
            <>
              <h1>Contact Us</h1>
              <FormInputContainer>
                <FormInput>
                  <TextField
                    id="name"
                    name="name"
                    variant="outlined"
                    label="Full Name"
                    helperText={formData.nameError ? "Please provide your full name" : ""}
                    fullWidth
                    error={formData.nameError}
                    onChange={handleChange}
                  />
                </FormInput>
                <FormInput>
                  <TextField
                    id="phone"
                    name="phone"
                    variant="outlined"
                    label="Contact Number"
                    helperText={formData.phoneError ? "Please provide your contact number" : ""}
                    fullWidth
                    error={formData.phoneError}
                    onChange={handleChange}
                  />
                </FormInput>
                <FormInput>
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    label="Email"
                    fullWidth
                    error={formData.emailError || formData.emailInvalid}
                    helperText={formData.emailError ? "Your email address is required" : formData.emailInvalid ? "Your email address is invalid" : ""}
                    onChange={handleChange} />
                </FormInput>
              </FormInputContainer>
              <FullFormInput>
                <TextField
                  id="message"
                  variant="outlined"
                  label="Message"
                  name="message"
                  multiline
                  fullWidth
                  onChange={handleChange}
                  helperText={formData.messageError ? "Please provide details about your request" : ""}
                  error={formData.messageError}
                  rows={4} />
              </FullFormInput>
              <Button disabled={formData.disabled} color="primary" variant="contained" onClick={handleSubmit}>SEND</Button>
            </>
        }

      </FormWrapper>

    </Form>
  )
}

export default Contact