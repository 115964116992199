import React from 'react'
import Img from "gatsby-image"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-image-lightbox/style.css'
import styled from "styled-components"

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
}


const SliderContainer = styled.div`

  @media screen and (max-width: 768px) {
    padding: 10px 30px;
  }
`


const CarouselPage = ({ image1, image2, image3, image4 }) => {
  return (
    <SliderContainer>
      <Slider {...settings} className="overflow-hidden">
        <Img fluid={image1.childImageSharp.fluid} />
        <Img fluid={image2.childImageSharp.fluid} />
        <Img fluid={image3.childImageSharp.fluid} />
        <Img fluid={image4.childImageSharp.fluid} />
      </Slider>
    </SliderContainer>
  )
}

export default CarouselPage