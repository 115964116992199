import { graphql, navigate } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import CarouselPage from '../components/carouselPage'
import Bio from '../components/bio'
import Card from '../components/card'
import styled from "styled-components"
import ContactForm from '../components/contact'
import { Button } from '@material-ui/core'


const Section = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 0;

  button {
    padding: 10px;
    width: 300px;
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    padding: 20px;
  
    button {
      width: 100%;
    }
  }
`

const ServiceBlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 30px 10px 30px;
    gap: 10px;
  }
`


const GalleryButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }
`

const Subtitle = styled.p`
  text-align: center;
  font-size: 1.4rem;
  padding: 30px 200px 30px 200px;

  @media (max-width: 768px) {
    padding: 0 50px;
  }
`

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo title="LH Building and Maintainence" />
      <Section>
        <StaticImage
          src="../images/logo.png"
          alt="LH Building and Services"
          width={960} />

        <StaticImage
          src="../images/van.jpg"
          alt="LH Building and Services"
          width={960} />
        <Subtitle>For <em className="orange">free</em> quotes, enquiries and a quality service please call <em>07842946138</em></Subtitle>
        <h1>AVAILABLE SERVICES</h1>
        <ServiceBlockWrapper>
          <Card img={data.plumbing.childImageSharp.fluid} title="Plumbing"></Card>
          <Card img={data.carpentry.childImageSharp.fluid} title="Carpentry"></Card>
          <Card img={data.tiling.childImageSharp.fluid} title="Tiling"></Card>
        </ServiceBlockWrapper>
        <ServiceBlockWrapper>
          <Card img={data.landscaping.childImageSharp.fluid} title="Landscaping"></Card>
          <Card img={data.painting.childImageSharp.fluid} title="Painting & Decorating"></Card>
          <Card img={data.worker.childImageSharp.fluid} title="Construction"></Card>
        </ServiceBlockWrapper>
      </Section>
      <Bio leeImage={data.leeImage} />
      <Section>
        <h1>PREVIOUS WORK</h1>
        <CarouselPage
          image1={data.image1}
          image2={data.image2}
          image3={data.image3}
          image4={data.image4}
        />
        <GalleryButtonContainer>
          <Button variant="contained" color="primary" onClick={() => navigate('/gallery')}>FULL GALLERY</Button>
        </GalleryButtonContainer>
      </Section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export const query = graphql`
  query Images {
    image1: file(relativePath: { eq: "gallery/IMG_20210220_180019_562.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "gallery/IMG_20210220_180019_558.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image3: file(relativePath: { eq: "gallery/IMG_20210225_172440_494.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "gallery/IMG_20210313_132721_247.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    leeImage: file(relativePath: { eq: "lee.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    worker: file(relativePath: { eq: "construction.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tiling: file(relativePath: { eq: "tiling.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carpentry: file(relativePath: { eq: "carpentry.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscaping: file(relativePath: { eq: "landscaping.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plumbing: file(relativePath: { eq: "plumbing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    painting: file(relativePath: { eq: "painting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
