import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"

const BioContainer = styled.div`
  background-color: #4c5e6f;
  color: #fff;
  padding: 30px;
  border-top: 1px solid #e6e8f0;
  border-bottom: 1px solid #e6e8f0;


  @media (max-width: 768px) {
    padding: 30px 50px;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin 0 auto;
`

const Column = styled.div`
  flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  margin-bottom: 10px;
  padding-top: 40px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0;
  }
  
`
const ImageColumn = styled.div`
  flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
`

const Header = styled.div`
  flex: 100%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  margin-bottom: 10px;
  text-align: center;
  h1 {
    line-height: 40px;
    color: #fff;
  }
  h2 {
    line-height: 30px;
    color: #fff;
  }
`

const styles = {
  width: "300px",
  height: "300px",
  borderRadius: "50%",
  margin: "0 auto"
};

const Bio = ({ leeImage }) => {
  return (
    <BioContainer>
      <ColumnWrapper>
        <Header>
          <h1>LH BUILDING AND MAINTENANCE</h1>
          <h2>Local family run company with 20 years experience across the building and maintenance trades</h2>
        </Header>
        <Column>
          <p>From full kitchen and bathroom refurbs to swapping taps and garden gates, we look to give a high quality and professional attitude from start to finish, no matter how big or small the job.</p>
          <p>Please contact us anytime for free no obligation quotes 🛠</p>
          <p>Local to Faversham and covering surrounding areas</p>
        </Column>
        <ImageColumn>
          <Img style={styles} fluid={leeImage.childImageSharp.fluid} />
        </ImageColumn>
      </ColumnWrapper>
    </BioContainer>
  )
}

export default Bio